import { IGatsbyImageData } from 'gatsby-plugin-image';
import * as React from 'react';
import {
  Container, Description, Image, Title,
} from './item.service.elements';

const ItemService = ({
  image, altImage, title, description,
}: Dto) => (
  <Container>
    <Image image={image} alt={altImage as string} objectFit="contain" />
    <Title>{title}</Title>
    <Description>{description}</Description>
  </Container>
);

export default ItemService;

interface Dto {
  image: IGatsbyImageData,
  altImage?: string
  title: string,
  description: string | React.ReactNode,
}

ItemService.defaultProps = {
  altImage: 'default alt',
};
