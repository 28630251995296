import { graphql, useStaticQuery } from 'gatsby';
import { getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import * as React from 'react';
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';
import BreadCrumb from '../bread-crumb/bread-crumb';
import {
  Banner, Containerbreadcrumbs, Image, Title, Wrapper, TabsLink,
} from './top-bar.elements';

const TopBar = ({
  title, imageName, next, previous,
}: Dto) => {
  const data = useStaticQuery(graphql`
  query {
    allContentfulAsset {
      edges {
        node {
          filename
          gatsbyImageData
        }
      }
    }
  }
`);

  const nodeImage = data.allContentfulAsset.edges.find((e: Node) => e.node.filename === imageName);
  const dataImage = nodeImage?.node ?? data.allContentfulAsset.edges[0].node;

  return (
    <>
      <BreadCrumb />
      <Banner>
        <Image image={getImage(dataImage.gatsbyImageData) as IGatsbyImageData} alt="Banner de servicios" className="img" />
        <Title>{title}</Title>
      </Banner>
      {
        previous || next ? (
          <Containerbreadcrumbs>
            <Wrapper>
              {
            previous ? <TabsLink to={`../${previous}`} className="prev"><BsArrowLeft />{previous.replaceAll('-', ' ')}</TabsLink> : null
          }
              {
            next ? <TabsLink to={`../${next}`} className="next">{next.replaceAll('-', ' ')}<BsArrowRight /></TabsLink> : null
          }
            </Wrapper>
          </Containerbreadcrumbs>
        ) : null
      }

    </>
  );
};

export default TopBar;

interface Dto {
  title: string,
  imageName: string,
  next?: string,
  previous?: string,
}

interface Node {
  node: {
    filename: string,
    gatsbyImageData: IGatsbyImageData,
  }
}

TopBar.defaultProps = {
  next: '',
  previous: '',
};
