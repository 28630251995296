import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

export const Section = styled.section`
  padding: 3rem 3rem;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  background-color: #E9ECEF;

  &:nth-child(odd){
    background-color: white;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
  max-width: 1300px;
  margin: auto;
`;

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;

  & p {
    padding: 15px 10px;
    text-align: justify;
    font-size: 1.1rem;
    line-height: 1.7em;
    width: fit-content;
  }

  .mid {
    width: 100%;
    @media screen and (min-width: 788px) {
      width: 50%;
    }
  }

  .semi-mid {
    width: 100%;
    @media screen and (min-width: 788px) {
      width: 45%;
    }
  }

  &.gap {
    justify-content: center;
  }

  & ul {
    position: relative;
    width: 100%;
    left: 35px;
    margin-bottom: 15px;

  }

  & li {
    margin-bottom: 7px;
  }
`;

export const Button = styled(Link)`
  position: relative;
  display: flex;
  align-items: center;
  gap: 5px;
  text-decoration: none;
  padding: 0.3em 1em;
  font-size: 1.3rem;
  color: #2D3E77;
  line-height: 1.7em;
  border: 2px solid;
  border-radius: 25px;
  transition: all .2s;
  margin-left: 10px;
  margin-bottom: 10px;
  
  & .plus {
    opacity: 0;
    position: absolute;
  }
  
  &:hover {
    & .plus {
      opacity: 1;
      right: 15px;
    }
    border: 2px solid transparent;
    background-color: rgba(0,0,0,.05);
    padding: 0.3em 2em 0.3em 0.7em;
  }
`;

export const Image = styled(GatsbyImage)`
  display: inline-block;
  position: relative;
  width: 100%;
  max-width: 100%;
  height: auto;

  @media screen and (min-width: 788px) {
    width: 45%;
  }
`;
