import { graphql, HeadFC, useStaticQuery } from 'gatsby';
import { getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import * as React from 'react';
import { BsPlusCircle } from 'react-icons/bs';
import ItemService from '../../components/item-service/item.service';
import Layout from '../../components/layout';
import Seo from '../../components/seo';
import TopBar from '../../components/top-bar/top-bar';
import {
  Button, Container, Section, Wrapper, Image,
} from '../../styles/servicios/cableado-estructurado.elements';

const ServicePage = () => {
  const data = useStaticQuery(graphql`
    query {
      contentfulPages(title: {eq: "Cableado estructurado"}) {
        title
        images {
          gatsbyImageData
        }
      }
    }
  `);
  const { images } = data.contentfulPages;
  return (
    <Layout>
      <main>
        <TopBar title="Servicio de redes de cableado estructurado" imageName="nosotros.jpg" next="redes-electricas" />
        <Section>
          <Wrapper>
            <Container>
              <Image image={getImage(images[0]) as IGatsbyImageData} alt="Centro de computo" objectFit="contain" />
              <Container className="mid">
                <p>
                  Identificamos las necesidades de su empresa y asesoramos la mejor solución en sistemas para la instalación de cableado estructurado. Como resultado, diseñamos una solución integral que permita satisfacer sus necesidades actuales y futuras. Para ello incluimos equipos activos y pasivos que integran la solución de cableado estructurado. Por medio de estos elementos integráramos los diferentes servicios de voz, datos, Internet y vídeo.

                  Nuestro servicio incluye asesoría, diseño, instalación y configuración de redes locales, cableado estructurado horizontal y vertical para centros de computo y datacenter.

                  Contamos con equipos de alta tecnología para el diseño de redes locales con conexión a puntos remotos y brindamos la certificación de funcionamiento a cada salida de la red, asegurando su funcionamiento adecuado.
                </p>
                <Button to="#lista">Ver lista de servicios <BsPlusCircle className="plus" color="red" /></Button>
              </Container>
            </Container>
          </Wrapper>
        </Section>

        <Section>
          <Wrapper>
            <Container className="gap">
              <ItemService title="Integración de redes de cableado estructurado" description="Diseño, evaluación y adaptación de cableado estructurado, ya sea horizontal o vertical, brindando soluciones de conectividad escalables para redes de comunicaciones. Gracias a nuestras soluciones proporcionamos integracion a todo tipo de infraestructuras." image={getImage(images[1]) as IGatsbyImageData} altImage="Cableado centro de computo" />

              <ItemService title="Certificación de redes de cableado estructurado" description="Certificación de redes de cableado estructurado, por medio de la  comparación del rendimiento de transmisión del sistema, evidenciando la calidad de los componentes y de la instalación realizada." image={getImage(images[2]) as IGatsbyImageData} altImage="Certificacion puntos de red" />

              <ItemService title="Integración de cableado UTP categoria 6, 6a y 7" description="Instalación de cable categoría 6, 6a y 7, migración de categoría 5 y 5e. Gracias a este estándar es posible alcanzar frecuencias de hasta 500 MHz y velocidades de hasta 10 Gbit/s, tanto para cables blindados como para cables no blindados." image={getImage(images[3]) as IGatsbyImageData} altImage="Conexion Switch de comunicaciones" />

              <ItemService title="Integración de fibra óptica" description="Soluciones basadas en cableado de fibra óptica, de acuerdo a estándares de Gigabit ethernet. Integración con soluciones de cableado estructurado de cobre. velocidades de hasta 100 Gbit/s, sistemas de alta densidad para centros de procesamiento de datos(CPD)." image={getImage(images[4]) as IGatsbyImageData} altImage="Integracion fibra optica" />

              <ItemService title="Mantenimiento cableado estructurado" description="Mantenimiento preventivo y/o correctivo de redes de cableado estructurado, servidores y equipos activos. Identificación y corrección de errores. Resolvemos las eventualidades en redes de comunicaciones de voz y datos de forma rápida, eficiente. Asistencia 24/7." image={getImage(images[5]) as IGatsbyImageData} altImage="Comparacion rack antes y despues de organizado" />

              <ItemService title="Componentes cableado estructurado" description="Suministro e instalación de componentes asociados al cableado estructurado, desde equipos activos como switch, router, servidores, hasta elementos como gabinetes y racks, conectores, protecciones, paneles modulares, bandejas y tuberías." image={getImage(images[6]) as IGatsbyImageData} altImage="Gabinetes y racks de diferentes tamaños" />
            </Container>
          </Wrapper>
        </Section>
        <Section>
          <Wrapper>
            <Container id="lista">
              <Container className="semi-mid">
                <ul>
                  <li>Soluciones para datos, voz y vídeo conferencia.</li>
                  <li>Soluciones de optimización para el despliegue de aplicaciones (terminal services).</li>
                  <li>Consultoría, diseño y configuración de redes locales (lan) y remotas (wan).</li>
                  <li>Para redes existentes ofrecemos diseño, certificación, evaluación, optimización e integración.</li>
                  <li>Instalación y configuración de equipos de comunicación.</li>
                  <li>Cableado estructurado, gestión inteligente de cableado estructurado y fibra óptica.</li>
                  <li>Instalación de cableado estructurado categoría 6, 6a y 7.</li>
                  <li>Diseño de redes según requerimiento del cliente y acorde con la estructura de las instalaciones.</li>
                  <li>Confección e instalación de fibra óptica om1, om2, om3, om4 y os2 acorde a la norma <a href="https://tiaonline.org/what-we-do/standards/" title="estandares tia">TIA</a> / EIA-568-c.3 fiber cabling components standard.</li>
                  <li>Instalación de sistema de tuberías y bandejas cablofil acorde a la norma tia/eia 569.</li>
                </ul>
              </Container>
              <Container className="semi-mid">
                <ul>
                  <li>Remodelaciones del cableado estructurado.</li>
                  <li>Mantenimiento preventivo y correctivo de cableado estructurado.</li>
                  <li>Montaje de plantas telefónicas.</li>
                  <li>Mantenimiento y peinados de rack o armarios.</li>
                  <li>Mantenimiento de Servidores para Cableado estructurado.</li>
                  <li>Instalación de centros de cableado.</li>
                  <li>Instalación y traslado de centros de computo.</li>
                  <li>Obra civil de cableado estructurado, redes lógicas y de potencia.</li>
                  <li>Instalacion y acondicionamiento de servidores</li>
                  <li>Instalacion y mantenimiento de datacenter</li>
                </ul>
                <Button to="/contactenos">Solicita una cotización o Agenda una visita <BsPlusCircle className="plus" color="red" /></Button>
                <Button to="/galeria">Visita nuestra Galeria <BsPlusCircle className="plus" color="red" /></Button>
              </Container>
            </Container>
          </Wrapper>
        </Section>
      </main>
    </Layout>
  );
};

export default ServicePage;

export const Head: HeadFC = () => <Seo title="Redes de cableado estructurado" />;
