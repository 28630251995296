import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

export const Container = styled.div`
  padding: 7px 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 0px;
  width: 100%;
  align-content: flex-start;
  background-color: white;


  @media screen and (min-width: 788px) {
    width: 33%;
  }

  &:nth-child(even) {
    background-color: #E9ECEF;
  }
`;

export const Title = styled.h2`
  width: 100%;
  text-align: center;
  padding: 15px 5px 0;
  font-size: 1.2rem;
`;

export const Description = styled.div`
  padding: 15px 10px;
  text-align: justify;
  font-size: 1.1rem;
  line-height: 1.7em;
`;

export const Image = styled(GatsbyImage)`
  display: inline-block;
  position: relative;
  width: 100%;
  max-height: 213px;
  height: auto;
`;
