import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

export const Containerbreadcrumbs = styled.div`
  background-color: white;
  padding: 7px 10px;
  display: flex;
  gap: 5px;
  font-size: 0.8rem;
  width: 100%;
`;

export const Breadcrumb = styled.div`
  display: flex;
  gap: 5px;
`;

export const Banner = styled.div`
  position: relative;
`;

export const Title = styled.h1`
  color: white;
  font-size: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 2.5rem;
  width: 100%;

  @media screen and (min-width: 788px) {
    width: auto;
  }
`;

export const Image = styled(GatsbyImage)`
  width: 100%;
  max-height: 360px;
  &::after {
    position: absolute;
    top: 0;
    content: "";
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
  }
`;

export const ItemLink = styled(Link)`
  text-decoration: none;
  color: #3DA5F2;
`;

export const Wrapper = styled.div`
  display: flex;
  margin: auto;
  padding: 7px 10px;
  position: relative;
  font-size: 1.2rem;
  font-weight: 500;
  width: 100%;
  max-width: 1300px;
`;

export const TabsLink = styled(Link)`
  text-decoration: none;
  display: flex;
  flex-wrap: wrap;
  width: auto;
  align-items: center;
  gap: 5px;
  color: black; 
  position: absolute;
  top: -50%;
  &.prev {
    left: 0;
  }

  &.next {
    right: 0;
  }
`;
